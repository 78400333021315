/* icons */
.icon-arrow-down {
    width: 10px;
    height: 6px;
    stroke-width: 0;
}

.icon-arrow-left {
    font-size: 14px;
    // width: 8px;
    // height: 14px;
    // stroke-width: 0;
}

.icon-bookmark {
    width: 12px;
    height: 14px;
    stroke-width: 0;
    opacity: 0.5;
}

.icon-bookmark-checked {
    width: 12px;
    height: 14px;
    stroke-width: 0;
    opacity: 1;
}

.icon-compare {
    width: 16px;
    height: 16px;
    stroke-width: 0;
}

.icon-chevron-down-small {
    fill: #575757;
}

.icon-heart {
    font-size: 24px;
}

.icon-heart-filled {
    font-size: 24px;
}

.icon-pagination-arrow-left,
.icon-pagination-arrow-right {
    width: 6px;
    height: 12px;
    stroke-width: 0;
}

.icon-search {
    width: 18px;
    height: 18px;
    stroke-width: 0;
}

.icon-grid {
    width: 20px;
    height: 20px;
    stroke-width: 0;
}

.icon-list {
    width: 18px;
    height: 18px;
    stroke-width: 0;
}

.icon-menu-donors,
.icon-menu-chat,
.icon-menu-video,
.icon-email,
.icon-saved.icon-saved,
.icon-liked,
.icon-scales,
.icon-settings,
.icon-calendar {
    fill: #B9B2F7;
    font-size: 24px;
    stroke-width: 0;
    opacity: 1;
}

.icon-close {
    width: 18px;
    height: 18px;
    stroke-width: 0;
}

.icon-burger {
    width: 24px;
    height: 24px;
    stroke-width: 0;
}

.icon-filter {
    width: 21px;
    height: 21px;
    stroke-width: 0;
}

.icon-x {
    width: 24px;
    height: 24px;
    stroke-width: 0;
}

.icon-location {
    width: 16px;
    height: 16px;
    stroke-width: 0;
    fill: #5A4CC3;
}

.icon-mail {
    width: 24px;
    height: 24px;
    stroke-width: 0;
    fill: #5A4CC3;
}

.icon-target {
    width: 14px;
    height: 14px;
    stroke-width: 0;
    fill: rgba(227, 171, 114, 1);
}

.icon-more {
    width: 16px;
    height: 16px;
    stroke-width: 0;
}

.icon-micro {
    width: 16px;
    height: 17px;
    fill: rgba(110, 107, 123, 1);
}

.icon-gallery {
    width: 16px;
    height: 17px;
    fill: rgba(110, 107, 123, 1);
}

.icon-inbox {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-sent {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-draft {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-starred {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-alert {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-trash {
    width: 20px;
    height: 20px;
    fill: #6e6b7b;
}

.icon-folder {
    width: 18px;
    height: 18px;
    fill: #6e6b7b;
}

.icon-tag {
    width: 18px;
    height: 18px;
    fill: #6e6b7b;
}

.icon-message {
    width: 18px;
    height: 18px;
    fill: #6e6b7b;
}

.icon-bin {
    width: 18px;
    height: 18px;
    fill: #6e6b7b;
}

.icon-paperclip {
    width: 16px;
    height: 16px;
}

.icon-star-checked {
    width: 20px;
    height: 20px;
    fill: #E3AB72;
    stroke: #E3AB72;
}

.icon-minimise {
    width: 26px;
    height: 26px;
}

.icon-maximize {
    width: 20px;
    height: 20px;
}

.icon-edit {
    width: 24px;
    height: 24px;
}

.icon-user {
    width: 24px;
    height: 24px;
}

.icon-settings-user {
    width: 18px;
    height: 18px;
}

.icon-settings-lock {
    width: 18px;
    height: 18px;
}

.icon-settings-info {
    width: 18px;
    height: 18px;
}

.icon-settings-headphones {
    width: 18px;
    height: 18px;
}

.icon-settings-bell {
    width: 18px;
    height: 18px;
}

.icon-arrow-right {
    width: 14px;
    height: 14px;
}

.icon-share {
    width: 20px;
    height: 20px;
    fill: #6E6B7B;
}

.icon-eye {
    width: 24px;
    height: 24px;
    fill: #6E6B7B;
}



/* end icons */