.range-slider {
    width: calc(100% - 10px);
    margin: auto;
    margin-top: 12px;
    
    &.rc-slider {
        .rc-slider-rail {
            height: 6px;
            width: 100%;
            background-color: rgba(179, 179, 179, 1);
            border-radius: 6px;
        }

        .rc-slider-track {
            height: 6px;
            border-radius: 6px;
            background-color: rgba(115, 103, 240, 1);
        }

        .rc-slider-handle {
            width: 14px;
            height: 14px;
            background: #fff;
            border: 2px solid rgba(115, 103, 240, 1);
            box-shadow: none !important;
            opacity: 1;
        }
    }
}