* {
    box-sizing: border-box;

    &::after,
    &::before {
        box-sizing: border-box;
    }

    &:focus {
        outline: 0 none;
    }

    &:not(html):not(body):not([data-disabled-custome-scroll="true"]) {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        // &::-webkit-scrollbar-thumb {
        //     background: $colors-night-rider;
        // }
    }
}

body {
    font-family: 'Sora', sans-serif;
    background: #F4FAFF;

    &.menu-hidden {
        position: fixed;
        overflow: hidden;
    }
}

.container {
    margin-left: 222px;
    transition: margin-left 0.3s ease;

    @media screen and (max-width: 1024px) {
        margin-left: 0;
    }

    &.full-wide {
        margin-left: 82px !important;
    
        @media screen and (max-width: 1024px) {
            margin-left: 0 !important;
        }
    }
}

.mobile_display_none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.display-none {
    display: none !important;
}

.tablet-display-none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.background-display-none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.overlay {
    &::before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(75,70,92,.5);
        z-index: 998;
        transition: all .25s ease;
        content: '';
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
    background-color: rgba(115, 103, 240, 1);
    margin-right: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.56px;
        text-transform: capitalize;
        text-align: center;
        padding-top: 2px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.checkbox-row {
    position: relative;
    display: flex;
    align-items: center;

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;        
    }

    label {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 2px solid rgba(186, 191, 199, 1);
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    input:checked + label:before,
    input.checkbox_checked + label:before {
        content: '';
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        background-color: #5A4CC3;
        filter: drop-shadow(0px 2px 4px #5A4CC3);
        overflow: hidden;
        border: 0 !important;
    }

    input:checked + label:after,
    input.checkbox_checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 4px;
        width: 10px;
        height: 5px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        rotate: -45deg;
    }
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    transition: 0.3s linear;
    z-index: 3;

    &:nth-child(2n + 1){
      background: rgba(#000,0.3);
    }

    .loader-1 {
        width: 48px;
        height: 48px;
        border: 5px solid #fff;
        border-bottom-color: rgb(115, 103, 240);
        border-radius: 50%;
        display: inline-block;
        animation: rotation 1s linear infinite;
        
        &.small {
            width: 20px;
            height: 20px;
            border: 2px solid #fff;
            border-bottom-color: rgb(115, 103, 240);
        }
    }
}

.loading-page {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
   }
    100% {
        transform: rotate(360deg);
   }
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #5A4CC3;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 2px 4px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
  
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5A4CC3 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.underline {
    text-decoration: underline;
}

.text-strong {
    font-weight: 600;
}